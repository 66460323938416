<template>
  <div>
    <video :key="cheerUrl" id="myVideo" width="10" height="10">
      <source :src="cheerUrl" type="video/mp4" />

      Your browser does not support HTML5 video.
    </video>

    <div>
      <v-app>
        <v-row justify="center">
          <v-dialog v-model="dialog" persistent max-width="390">
            <!-- <template v-slot:activator="{ on, attrs }">
              <v-btn v-bind="attrs" v-on="on">
                Open Dialog
              </v-btn>
            </template> -->
            <v-card>
              <v-card-title class="headline">
                Let this site play audio?
              </v-card-title>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-progress-circular
                  v-bind:style="{ display: loader }"
                  :size="50"
                  color="primary"
                  indeterminate
                ></v-progress-circular>

                <v-btn
                  v-if="loader == 'none'"
                  v-on:click="playAudio"
                  color="green darken-1"
                  text
                >
                  Yes
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-row>
      </v-app>
    </div>

    <div class="envelope">
      <div class="envelope__tab"></div>
      <div class="envelope__letter">
        <p>{{ cheerTitle }}</p>
      </div>
      <div class="envelope--base"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Home",
  data() {
    return {
      loader: "",
      music: "",
      dialog: true,
      cheerTheme:
        "https://firebasestorage.googleapis.com/v0/b/cheersat7.appspot.com/o/C27EAA10-C59D-409E-AD4A-A2C88858339C.jpeg?alt=media&token=c1be21da-84b2-44b8-a3af-0ec587405ff5",
      cheerUrl:
        "https://firebasestorage.googleapis.com/v0/b/cheersat7.appspot.com/o/cheers%2FStill%20The%20One.mp3?alt=media&token=61c02dec-9188-40c7-b7e9-66006c0c5e8a",
      cheerTitle: ""
    };
  },
  mounted() {
    window.addEventListener("focus", function() {
      console.log("this");
      var vid = document.getElementById("myVideo");
      vid.play();
    });
    window.addEventListener("blur", function() {
      console.log("that");
      var vid = document.getElementById("myVideo");
      vid.pause();
    });
    window.addEventListener("beforeunload", function(e) {
      var confirmationMessage = "o/";

      var vid = document.getElementById("myVideo");
      vid.pause();

      (e || window.event).returnValue = confirmationMessage; //Gecko + IE
      return confirmationMessage; //Webkit, Safari, Chrome
    });

    const db = this.$firebase.firestore();

    const queryString = window.location.search;

    const urlParams = new URLSearchParams(queryString);

    const instaCelebrationId = urlParams.get("instaCelebrationId");

    const instaThemeId = urlParams.get("instaThemeId");

    const title = urlParams.get("title");

    document.body.style.backgroundImage = `url(${this.cheerTheme})`;

    if (instaCelebrationId) {
      (async () => {
        const celebrationCheer = await db
          .collection("cheers")
          .doc(instaCelebrationId)
          .get();

        const celebrationTheme = await db
          .collection("insta_celebration")
          .doc(instaThemeId)
          .get();

        this.cheerUrl = celebrationCheer.data().url;
        this.cheerTheme = celebrationTheme.data().theme_url;
        this.cheerTitle = title;
        this.loader = "none";

        document.body.style.backgroundImage = `url(${
          celebrationTheme.data().theme_url
        })`;
      })();
    } else this.loader = "none";
  },
  methods: {
    playAudio: function() {
      var vid = document.getElementById("myVideo");

      this.dialog = false;
      var audio = new Audio(this.cheerUrl); // path to file
      this.music = audio;
      vid.play();
    }
  }
};
</script>

<style>
html,
body {
  height: 100%;
}
body {
  background-position: center center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
  background-color: #999;
}

div,
body {
  margin: 0;
  padding: 0;
  font-family: exo, sans-serif;
}

.theme--light.v-application {
  background: #ffffff00 !important;
  height: 0px;
}

/* COLORS */
body {
  display: flex;
  justify-content: center;
}
.envelope {
  margin-top: 60vh;
  width: 224px;
  height: 224px;
  overflow: hidden;
  position: relative;
  transition: all ease;
  animation: tremble 1s infinite;
}
.envelope--base {
  top: 100px;
  z-index: 2;
  width: 220px;
  height: 121px;
  position: relative;
  border-radius: 4px;
  border: 2px solid #00000024;
  background-color: #f5f4f4;
}
.envelope__tab {
  left: 28px;
  z-index: 1;
  bottom: 26px;
  width: 212px;
  height: 140px;
  position: absolute;
  transform: rotateZ(45deg);
  border-top-left-radius: 30px;
  background-color: #f5f4f4;
  border: 2px solid #00000024;
}
.envelope__letter {
  left: 29px;
  z-index: 3;
  width: 158px;
  bottom: 17px;
  height: 181px;
  cursor: pointer;
  font-size: 16px;
  font-style: oblique;
  padding: 12px 18px;
  border-radius: 8px;
  position: absolute;
  box-sizing: border-box;
  background-size: cover;
  color: #4c4444;
  border: 1px solid #00000024;
  transition: all 0.6s linear 0.4s;
  background-image: url("https://i.pinimg.com/564x/03/c3/fe/03c3fe8f10c79787d45f0cff0bc7bb52.jpg");
}
.envelope__letter:hover {
  height: 120px;
  color: #4c4444;
}
.envelope__letter:before {
  content: "";
  height: 0;
  width: 6px;
  right: -34px;
  bottom: -17px;
  position: absolute;
  border-top: 56px solid transparent;
  border-left: 107px solid #ffffff;
  border-right: 107px solid #ffffff;
  border-bottom: 65px solid #f5f4f4;
}
.shadow {
  width: 230px;
  height: 30px;
  position: absolute;
  background-color: #75757524;
  border-radius: 50%;
  bottom: 26vh;
  animation: shadow 3s infinite;
}
@keyframes shadow {
  0% {
    width: 230px;
  }
  50% {
    width: 200px;
  }
  100% {
    width: 230px;
  }
}
@keyframes tremble {
  0% {
    transform: rotate(-1deg);
  }
  50% {
    transform: rotate(0deg);
  }
  75% {
    transform: rotate(1deg);
  }
  100% {
    transform: rotate(0deg);
  }
}
</style>
